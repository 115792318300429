import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

// Third party CSS
import 'swiper/css'

// Interface
import { SliderProps } from 'components/flex/Slider/SliderShell'

const Icon = styled(Plaatjie)`
  height: 101px;
  opacity: 0.8;

  & img {
    object-fit: contain !important;
  }

  &:hover {
    opacity: 1;
  }
`

const SliderDefault: React.FC<SliderProps> = ({ fields }) => {
  SwiperCore.use([Autoplay])

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <ParseContent content={fields.description} className="mb-5" />
            <Swiper
              autoplay
              centerInsufficientSlides
              breakpoints={{
                0: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 100,
                },
              }}
            >
              {fields.slides?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <NavLink to={slide?.link?.url || '#'}>
                    <Icon image={slide?.icon} alt="" />
                    <span className="text-hidden">{slide?.link?.title}</span>
                  </NavLink>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SliderDefault
